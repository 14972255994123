import { Injectable } from "@angular/core";
import { ClientsUsersRolesPermissionsRequest } from "@app/shared/interfaces/client-users-roles-permissions/clients-users-roles-permissions.request";
import { ClientUserRolesPermissions } from "@app/shared/interfaces/client-users-roles-permissions/clients-users-roles-permissions.response";
import { AbstractCrudService } from "../../abstract/abstract-crud.service";
import { AbstractHelper } from "../../abstract/abstract-helper.class";
import { HttpUpdateMethods } from "../../abstract/abstract.service";

@Injectable()
export class ClientsRolesPermissionsService extends AbstractCrudService<
  ClientUserRolesPermissions,
  ClientsUsersRolesPermissionsRequest
> {
  protected override feature = 'clients-users-roles-permissions';
  protected override endPoint = `/clients-users-roles-permissions/`;
  updateBulk(permissionsModel: ClientsUsersRolesPermissionsRequest): AbstractHelper<ClientUserRolesPermissions> {
    const endpoint = `${this.endPoint}*/bulk`;
    return this.update(HttpUpdateMethods.PUT, '', permissionsModel, [], endpoint);
  }
}
